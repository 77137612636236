import { LitElement, html, css, customElement, property } from "lit-element"
import { GlobalCSS } from "../css"
import { GBPage } from "./gbpage"


@customElement("app-notfound")
export class NotFound extends GBPage {

    render() {
        return html`
            <h1>404 Not Found!</h1>
        `
    }

    static styles = [GlobalCSS, css`
        
    `]
}