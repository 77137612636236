import {LitElement, html, css, customElement, property, query} from "lit-element"
import { GlobalCSS } from "../css"
import { GBPage } from "./gbpage"

//import assetHeaderImg from '../../assets/images/lucia.jpg'


@customElement("app-home")
export class Home extends GBPage {

    render() {
        return html`
                <div id="head-background">
                    <div id="head-background-2"></div>
                    <!--<img id="head-icon" src=""> -->
                    <img id="head-fade" src="">
                    <div id="head-headline">
                        <div>GEBATZENS</div>
                        <!--<div></div> -->
                    </div>
                </div>
                
                <main>
                    <div id="navigation-marker"></div>
                    <div id="first-content-block" class="content-block">
                        <div class="content-headline"><div id="concept-anchor"></div>Was ist Gebatzens?</div>
                        <div class="content-sub-headline">Gebatzens ist ein sehr altes Projekt</div>
                        <div class="content-text">
        
                            <div>
                            Gebatzens ist ein "Projekt" von einer Gruppe Stundenten. Es ist ein "Projekt" und kein richtiges Projekt, 
                            weil ein Projekt in der Regel ein Ziel hat und dieses "Projekt" kein Ziel verfolgt, sondern nur eine Sammlung 
                            von einer Menge Dingen ist, u.a. Müll und unbrauchbarer Schrott, wie Sie hier auf dieser Website zu Hauf finden werden.<br><br>

                            Das hier ist also eher eine "Projektsammlung" als ein "Projekt"..
                            Erklärung: Koornelius McHaino.                            
                            </div>
        
                            <div>
                            Achtung, wichtige Mitteilung von G.B.:<br><br>

                            Hallo, ich bin Günner Barz und in meinen Videos gibt’s immer was unnötiges, worüber man lachen kann. <br><br>
                            ALSO abbonieren und immer schön meine Episoden schauen, sonst gibt’s HAUE!! Sonst verpasst ihr ja, wie 
                            ich mega viel Geld verdiene, um mir noch mehr zu fressen kaufen zu können!!! Denn ich ja auch ein richtiger
                            Vollpfosten! Also man sieht sich.
                            </div>
                            
                        </div>
        
                        <div class="content-headline"><div id="app-anchor"></div>GBAnimations</div>
                        <div class="content-sub-headline">Bild für Bild</div>

                        <div class="content-text">
        
                            <div>
                            GBAnimations ist für die Animationen, Bilder und die Grafik sowie die weitere Gestaltung und die Spielideen zuständig. 
                            Diese werden von GBSoftware weiter entwickelt. Außerdem werden in GBA auch Zeichentrickfilme wie die Günter Serie 
                            und kurze Cartoons (Hausmeisterfilme) produziert.<br><br>

                            Die Günter-Filme wurden in 2 Staffeln von Januar 2012 - Dezember 2013 produziert und sind die umfangewichste GBA-Serie.                          
                            </div>
        
                            <div>
                            Hinzu kam später die Hausmeisterkurzfilm -Serie, welche den Hausmeister beinhaltet, der komische Begegnungen aufgrund 
                            von seiem Bedürfnis, mit einer Jacht nach Stalingrad zu fahren, macht.<br><br>

                            Im Jahr 2017 und 2018 wurde eine neue Staffel Günter mit einem Kliffhänger als Staffelfinale produdiert, auf dessen
                            Auflösung die GB-Fangemeinde bis heute wartet.
                            </div>
                            
                        </div>

                        <div class="content-headline"><div id="why-anchor"></div>Kostenloses Programm</div>
                        <div class="content-sub-headline">Weiteres Zeug</div>
        
                        <code-block id="custom-code" language="go">
package main

import ( "fmt" "math" ) 

func main() {
    for { 
        fmt.Print("Geben Sie die Zahl an:")
        var a float64 fmt.Scanf("%f", &a)

        if a < 0 {
            panic("Es ist leider negativ.")
        }

        var a2 float64 = math.Sqrt(a)
        fmt.Printf("\\nDie Wurzel ist: %f", a2)
        fmt.Print("\\nWollen Sie denn noch eine Wurzel ziehen?")
        var z string
        fmt.Scanf("%s", &z)

        if z != "ya" {
            break
        } 
    }

    fmt.Println("\\nTschüss...")
}
                        </code-block>
        
                       <!-- <div class="content-headline"><div id="contact-anchor"></div>Contact</div>
                        <div class="content-sub-headline">If interested, you can write me an E-Mail</div>
        
                        <app-content-box header="Kontaktformular" centerHeader></app-content-box>
                            <form id="contact-form" @submit="${this.sendContactForm}" onSubmit="return false">
                                <select name="type" id="contact-form-type" required="required">
                                    <option value="" disabled="disabled" selected="selected">Select event</option>
                                    <option value="wedding">wedding</option>
                                    <option value="funeral">funeral</option>
                                    <option value="other-event">other event</option>
                                </select>
                                <input type="text" name="firstname" id="contact-form-firstname" placeholder="First Name" required>
                                <input type="text" name="lastname" id="contact-form-lastname" placeholder="Last Name" required>
                                <input type="email" name="email" id="contact-form-email" placeholder="E-Mail" required>
                                <textarea name="message" id="contact-form-message" placeholder="Your message" required></textarea>
                                <div id="contact-form-checkboxes">
                                    <input type="checkbox" id="contact-form-checkbox" required>
                                    <label for="contact-form-checkbox">I accept the</label><span> </span><a class="link" href="https://cedgetec.com/privacy" target="_blank">privacy policy</a>
                                </div>
                                <button id="contact-form-submit" type="submit">Absenden</button>
                            </form>
                        </app-content-box>
                
                        <app-content-box header="E-Mail und Telefon" centerHeader centerContent>
                            <div id="contact-info">
                                <a class="link contact-link" href="mailto:info@cedgetec.com">info@cedgetec.com</a>
                                <a class="link contact-link" href="tel:004940466665250">040 / 466665250</a>
                            </div>
                        </app-content-box> -->
        
        
                    </div>
        
                </main>
        `
    }

    static styles = [GlobalCSS, css`
        
        #head-background {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;
            height: 60vh;
            z-index: 2;
            background-color: rgb(169 41 41);
        }

        #head-background-2 {
            top: 0;
            position: absolute;
            width: 100%;
            height: 30vh;
            background-color: #d23636;
            z-index: 3;
        }
        
        #head-headline {
            display: flex;
            flex-direction: row;
            color: white;
            z-index: 13;
        }
        
        #head-headline > div:nth-child(1) {
            font-size: 5.5rem;
            font-weight: 800;
            line-height: 6rem;
        }
        
        #head-headline > div:nth-child(2) {
            font-size: 5.5rem;
            font-weight: 300;
            line-height: 6rem;
        }
        
        #head-icon {
            margin-right: 3rem;
            width: 15%;
            z-index: 1;
        }
        
        @media screen and (max-width: 102rem) {
            
            #head-headline > div:nth-child(1) {
                font-size: 5rem;
                line-height: 5rem;
            }
            
            #head-headline > div:nth-child(2) {
                font-size: 5rem;
                line-height: 5rem;
            }
        
            #head-icon {
                width: 12%;
            }
        }
        
        @media screen and (max-width: 65rem) { 
            #head-background {
                flex-direction: column;
            }
        
            #head-headline {
                margin-left: 0;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
            
            #head-headline > div:nth-child(1) {
                font-size: 4rem;
                line-height: 5rem;
            }
        
        }

        @media screen and (max-width: 50rem) {
            #head-background {
                align-items: center;
            }

            #head-headline {
                margin-top: 32rem;
                max-width: 90%;
            }

            #head-headline > div:nth-child(1) {
                font-size: 3.1rem;
                line-height: 4rem;
            }
        }

        @media screen and (max-height: 40rem) {
            #head-headline {
                margin-top: 25rem;
                max-width: 90%;
            }

            #head-headline > div:nth-child(1) {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
        }
        
        #head-img {
            position: absolute;
            width: 100%;
            height: 100vh;
            z-index: 12;
            box-sizing: border-box;
            background-origin: border-box;
            background-position: center center;
            object-fit: cover;
        }
        
        main {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }
        
        #navigation-marker {
            position: absolute;
            top: -5.5rem;
        }
        
        .content-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 52%;
            font-size: 1.1rem;
            color: rgb(92, 92, 92);
        }

        #first-content-block {
            margin-top: 1.5rem;
        }
        
        @media screen and (max-width: 90rem) {
            .content-block {
                width: 70%;
            }
        }
        
        @media screen and (max-width: 65rem) {
            .content-block {
                width: 80%;
            }
        }
        
        @media screen and (max-width: 50rem) {
            .content-block {
                width: 60%;
            }
        }

        @media screen and (max-width: 30rem) {
            .content-block {
                width:77%;
            }
        }
        
        .content-headline {
            margin-top: 4rem;
            font-size: 3.2rem;
            font-weight: 600;
            color: black;
            text-align: center;
            line-height: 3.8rem;
            position: relative;
            /* color: rgba(31, 123, 255, .8); */
        }

        .content-headline::after {
            position: absolute;
            content: "";
            display: block;
            bottom: .1rem;
            width: 100%;
            height: 1.4rem;
            background: rgb(211, 8, 18, .4);
            z-index: -1;
        }

        @media screen and (max-width: 30rem) {
            .content-headline::after {
                bottom: .4rem;
                height: 1.1rem;
            }
        }
        
                
        .content-headline > div {
            position: absolute;
            top: -8rem;
        }

        @media screen and (max-width: 35rem) {
            .content-headline {
                margin-top: 2rem;
                font-size: 2.5rem;
            }
        }
        
        .content-sub-headline {
            margin-top: 1rem;
            margin-bottom: 4rem;
            font-size: 1.8rem;
            font-weight: 400;
            text-align: center;
            opacity: .7;
        }

        @media screen and (max-width: 50rem) {
            .content-sub-headline {
                margin-top: .5rem;
                margin-bottom: 2.9rem;
            }
        }

        @media screen and (max-width: 35rem) {
            .content-sub-headline {
                font-size: 1.3rem;
            }
        }
        
        .content-sub-headline-between {
            margin-top: 3rem;
        }
        
        .content-text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.7rem;
        }
        
        
        @media screen and (max-width: 50rem) {
            .content-text {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
                row-gap: 1rem;
            }
        } 
        
        #testimonial-container {
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        #testimonial-container > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 50%;
        }

        @media screen and (max-width: 50rem) {

            #testimonial-container > div {
                width: 100%;
            }

            #testimonial-container {
                flex-direction: column;
            }

        } 

        .testimonial {
            margin-bottom: 2.8rem;
            text-align: center;
            line-height: 1.9rem;
            width: 80%;
        }

        @media screen and (max-width: 50rem) {
            #testimonial-container {
                width: 100%;
            }

            .testimonial {
                width: 75%;
            }
        } 

        .testimonial > div:nth-child(2) {
            margin-top: 1.4rem;
            font-weight: 600;
            font-size: 1.3rem;
            color: rgb(0,0,0, .3);
        }

        #testimonial-container > div:nth-child(2) > div:last-child {
            margin-bottom: 0;
        }

        .audio-box {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }

        .audio-text {
            margin-top: 1.5rem;
            margin-bottom: 2.5rem;
            font-weight: 200;
            font-size: 1.4rem;
            opacity: .8rem;
            text-align: center;
        }

        /*#blocks-left {
            bottom: -6rem;
            left: 0;
            position: absolute;
            width: 22rem;
            z-index: 3;
        }*/

        #blocks-left {
            top: 4rem;
            left: 1rem;
            position: absolute;
            width: 29rem;
            opacity: .9;
        }
        
        #blocks-right {
            top: -.5rem;
            right: 2rem;
            position: absolute;
            width: 26rem;
            opacity: 1;
            z-index: 14;
        }

        @media screen and (max-width: 90rem) {
            #blocks-right {
                display: none;
            }
        
            #blocks-left {
                display: none;
            }
        }
        
        
        /* KONTAKT SEITE */

        #contact-form {
            width: 25rem;
            max-width: 100%;
            z-index: 8;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form {
                width: 100%;
            }
        }
        
        #contact-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.1rem;
        }
        
        .contact-link {
            margin-top: 1rem;
        }
        
        input[type="text"], input[type="email"], input[type="submit"], select, textarea, button {
            box-sizing: border-box;
            margin: 0.5rem;
            padding: 0.6rem;
            min-width: 100%;
            max-width: 100%;
            border: 0.18rem solid rgb(152, 34, 72);
            background-color: #ffffff;
            outline: none;
        }
        
        select {
            cursor: pointer;
        }
        
        select option {
            outline: none;
        }
        
        textarea {
            min-height: 9rem;
            max-height: 16rem;
        }
        
        #contact-form-checkboxes {
            user-select: none;
            margin: 0.5rem;
            text-align: left;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form-checkboxes {
                text-align: center;
            }
        }
        
        input[type="checkbox"] {
            margin-right: 0.5rem;
        }
        
        #contact-form-message {
            font: 400 13.3333px Arial;
        }
        
        button {
            margin-top: 1rem;
            transition: background-color 0.3s, color 0.3s;
            cursor: pointer;
        }
        
        button:hover {
            background-color: rgb(152, 34, 72);
            color: #ffffff;

    `]
}