import { LitElement, html, css, customElement, property } from "lit-element"
import { GlobalCSS } from "../css"

// Import assets here
// import assetExample from '../../assets/images/example.svg'

@customElement("app-footer")
export class Footer extends LitElement {
    // @property({type: String}) private myProperty: string // Observed property Example

    constructor() {
        super()
        // this.myProperty = '' // Default value
    }

    render() {
        return html`
            <footer>

                <div id="footer-left">
                    © Cedgetec 2020
                </div>
    
                <div id="footer-right">
                    <a class="footer-item" href="https://cedgetec.com/privacy">Privacy</a>
                    <a class="footer-item" href="https://cedgetec.com/imprint">Imprint</a>
                </div>
     
            </footer>
        `
    }

    static styles = [GlobalCSS, css`
        footer {
            margin-top: 5rem;
            position: absolute;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 5rem;
            background-color: rgb(152, 34, 72);
            color: white;
            font-size: 1.1rem;
        }

        @media screen and (max-width: 50rem) {
            footer {
                height: 7rem;
                flex-direction: column;
                justify-content: center;
            }
        }
        
        #footer-right {
            display: flex;
            flex-direction: row;
        }

        @media screen and (max-width: 50rem) {
            #footer-right {
                margin-top: 1rem;
            }
        }
        
        .footer-item {
            margin-right: 1rem;
            margin-left: 1rem;
            color: white;
            text-decoration: none;
        }
    `]
}