import { LitElement, html, css, customElement, property } from "lit-element"
import { GlobalCSS } from "../css"
import { GBPage } from "./gbpage"

@customElement("app-news")
export class News extends GBPage {


    render() {
        return html`
            <h1>News</h1>
        `
    }

    static styles = [GlobalCSS, css`
        
    `]
}