import {LitElement, html, css, customElement, property, query} from "lit-element"
import { GlobalCSS } from "../css"
import { GBPage } from "./gbpage"

import assetGb from '../../assets/images/gb.svg'
import assetGbBg from '../../assets/images/bg_gb.svg'
import assetGbFernsehr from '../../assets/images/gb_fernsehr.svg'
import assetS1E1 from '../../assets/images/S1E1.jpg'
import assetS1E2 from '../../assets/images/S1E2.jpg'
import assetS1E3 from '../../assets/images/S1E3.jpg'
import assetS1E4 from '../../assets/images/S1E4.jpg'
import assetS1E5 from '../../assets/images/S1E5.jpg'
import assetS1E6 from '../../assets/images/S1E6.jpg'
import assetS1E7 from '../../assets/images/S1E7.jpg'
import assetS1E8 from '../../assets/images/S1E8.jpg'
import assetS1E9 from '../../assets/images/S1E9.jpg'
import assetS1E10 from '../../assets/images/S1E10.jpg'
import assetS1E11 from '../../assets/images/S1E11.jpg'
import assetS1E12 from '../../assets/images/S1E12.jpg'
import assetS1E13 from '../../assets/images/S1E13.jpg'
import assetS1E14 from '../../assets/images/S1E14.jpg'
import assetS1E15 from '../../assets/images/S1E15.jpg'
import assetS1E16 from '../../assets/images/S1E16.jpg'

@customElement("app-gunter")
export class Home extends GBPage {

    render() {
        return html`
                <div id="head-background">
                <div id="gb-container">
                    <img id="bg-gb" src="${assetGbBg}">
                    <img id="gb" src="${assetGb}">
                    <img id="gb-fernsehr" src="${assetGbFernsehr}">
                </div>
                    <!--<img id="head-icon" src=""> -->
                    <div id="head-headline">
                        <div>GÜNTER BARZ</div>
                        <!--<div></div> -->
                    </div>
                </div>
                
                <main>
                    <div id="navigation-marker"></div>
                    <div id="first-content-block" class="content-block">
                        <div class="content-headline"><div id="concept-anchor"></div>Produktion</div>
                        <div class="content-sub-headline">2012 - 2018</div>
                        <div class="content-text">
        
                            <div>
                            Staffel 1 wurde Anfang 2012 begonnen mit der ersten Episode "Zoff im Schwimmbad". Diese Staffel beinhaltet 
                            qualitativ nicht so hochwertige, kurze Filme, meist nur mit Scatchen, die den realen Alltag wiederspiegeln, sowie 
                            Günters Leben mit dem sozialen Abschaum zeigen, welcher seine Freunde Ede und Willi beinhaltet.<br><br>

                            Staffel 2 beginnt mit dem Film "Rund um die Welt", welcher mit 25 Minuten Länge der längste Film der Serie ist. 
                            Die Qualität der Animationen steigert sich innerhalb dieses Films um ein Vielfaches und gibt das sich nun in der 
                            gesamten Staffel noch verbessernde höhere Produktionsniveau der Filme an.
                            </div>
                            <div>
                            Die Folgen der Staffel 2 sind im Schnitt um die 3:40 Min. lang, es gibt aber ein Special, welches 9:00 Min. Länge umfasst: 
                            "Günter sucht den Superstar". Es schließt die Staffel, welche innerhalb der Jahre 2012/2013 premierte, ab.<br><br>

                            Am 27. Mai 2017 erschien der erste Günter-Film seit 2013. Er war ein Kurzfilm, der als Einzelproduktion angefertigt wurde. 
                            Darauf folgte eine sehr kurze 3. Staffel mit richtig guter Qualität und besserer Musik.                          
                            </div>
                            
                        </div>
        
                        <div class="content-headline"><div id="app-anchor"></div>Staffel 1</div>
                        <div class="content-sub-headline">2012</div>

                        <div class="gb-folgen-container">
                        
                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">1</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E1}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Zoff im Schwimmbad</div>
                                    <div>28. Januar 2012</div>
                                    <div>2:12 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">2</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E2}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter nervt Leute I</div>
                                    <div>11. Februar 2012</div>
                                    <div>0:32 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">3</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E3}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter nervt Leute II</div>
                                    <div>11. Februar 2012</div>
                                    <div>1:13 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">4</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E4}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter der Babysitter Teil 1</div>
                                    <div>10. März 2012</div>
                                    <div>1:50 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">5</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E5}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter der Babysitter Teil 2</div>
                                    <div>10. März 2012</div>
                                    <div>2:42 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">6</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E6}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter und sein Teich</div>
                                    <div>2. April 2012</div>
                                    <div>1:14 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">7</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E7}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter beim Angeln</div>
                                    <div>2. April 2012</div>
                                    <div>1:01 Min.</div>
                                </div>
                            </div>
                            
                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">8</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E8}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günters Interview</div>
                                    <div>11. Mai 2012</div>
                                    <div>1:36 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">9</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E9}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Teezeit!</div>
                                    <div>11. Mai 2012</div>
                                    <div>1:03 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">10</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E10}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Sommerzeit!</div>
                                    <div>2. Juni 2012</div>
                                    <div>2:55 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">11</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E11}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Das Erdbeben</div>
                                    <div>13. Juni 2012</div>
                                    <div>1:04 Min.</div>
                                </div>
                            </div>
                            
                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">12</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E12}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Bald ist Weihnachten</div>
                                    <div>13. Juni 2012</div>
                                    <div>1:08 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">13</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E13}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>In Liebe zu meinem Haus</div>
                                    <div>14. Juni 2012</div>
                                    <div>0:52 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">14</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E14}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Grillfest mit Hindernissen</div>
                                    <div>14. Juni 2012</div>
                                    <div>1:49 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">15</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E15}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Alles ist möglich</div>
                                    <div>15. Juni 2012</div>
                                    <div>1:51 Min.</div>
                                </div>
                            </div>

                            <div class="gb-folge">
                                <div class="gb-folge-container">
                                    <div class="contain-container">
                                        <div class="number">16</div>
                                        <div class="number-triangle"></div>
                                    </div>
                                    
                                    <img class="gb-folge-thumbnail" src="${assetS1E16}">
                                </div>
                                <div class="gb-fact-container">
                                    <div>Günter und die Fliege</div>
                                    <div>14. Juli 2012</div>
                                    <div>0:55 Min.</div>
                                </div>
                            </div>
                        
                        </div>

                        <div class="content-headline"><div id="why-anchor"></div>Kostenloses Programm</div>
                        <div class="content-sub-headline">Weiteres Zeug</div>
        
                      
        
        
                    </div>
        
                </main>
        `
    }

    static styles = [GlobalCSS, css`
        
        #head-background {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;
            height: 60vh;
            z-index: 2;
            background-color: rgb(169 41 41);
        }


        #bg-gb {
            position: absolute;
            width: 100%;
            height: 60vh;
            object-position: center center;
            object-fit: cover;
            background-origin: border-box;
            box-sizing: border-box;
        }

        #gb-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            height: 60vh;
        }

        #gb {
            width: 26rem;
            z-index: 4;
        }

        #gb-fernsehr {
            right: 0;
            width: 40rem;
            z-index: 4;
        }

        .gb-folge {
            height: 10rem;
            display: flex;
            flex-direction: row;
        }

        .gb-fact-container {
            margin-left: 2rem;
        }

        .gb-fact-container > div:nth-child(1) {
            font-weight: 800;
            font-size: 1.7rem;
        }

        .gb-fact-container > div:nth-child(2), .gb-fact-container > div:nth-child(3) {
            font-size: 1.2rem;
        }

        .gb-folge-container {
            width: 15rem;
        }

        .gb-folge-thumbnail {
            width: 15rem;
            position: absolute;
            object-position: center center;
            object-fit: cover;
            background-origin: border-box;
            box-sizing: border-box;
        }

        .contain-container {
            
        }

        .number {
            margin-top: .1rem;
            margin-left: .7rem;
            position: absolute;
            color: black;
            font-weight: 800;
            font-size: 1.2rem;
            z-index: 2;
        }

        .number-triangle {
            position: absolute;
            width: 0;
            height: 0;
            border-top: 3.5rem solid red;
            border-right: 3.5rem solid transparent;
            z-index: 1;
        }

        #head-background-2 {
            top: 0;
            position: absolute;
            width: 100%;
            height: 30vh;
            background-color: #d23636;
            z-index: 3;
        }
        
        #head-headline {
            display: flex;
            flex-direction: row;
            color: white;
            z-index: 13;
        }
        
        #head-headline > div:nth-child(1) {
            font-size: 5.5rem;
            font-weight: 800;
            line-height: 6rem;
        }
        
        #head-headline > div:nth-child(2) {
            font-size: 5.5rem;
            font-weight: 300;
            line-height: 6rem;
        }
        
        #head-icon {
            margin-right: 3rem;
            width: 15%;
            z-index: 1;
        }
        
        @media screen and (max-width: 102rem) {
            
            #head-headline > div:nth-child(1) {
                font-size: 5rem;
                line-height: 5rem;
            }
            
            #head-headline > div:nth-child(2) {
                font-size: 5rem;
                line-height: 5rem;
            }
        
            #head-icon {
                width: 12%;
            }
        }
        
        @media screen and (max-width: 65rem) { 
            #head-background {
                flex-direction: column;
            }
        
            #head-headline {
                margin-left: 0;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
            
            #head-headline > div:nth-child(1) {
                font-size: 4rem;
                line-height: 5rem;
            }
        
        }

        @media screen and (max-width: 50rem) {
            #head-background {
                align-items: center;
            }

            #head-headline {
                margin-top: 32rem;
                max-width: 90%;
            }

            #head-headline > div:nth-child(1) {
                font-size: 3.1rem;
                line-height: 4rem;
            }
        }

        @media screen and (max-height: 40rem) {
            #head-headline {
                margin-top: 25rem;
                max-width: 90%;
            }

            #head-headline > div:nth-child(1) {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
        }
        
        #head-img {
            position: absolute;
            width: 100%;
            height: 100vh;
            z-index: 12;
            box-sizing: border-box;
            background-origin: border-box;
            background-position: center center;
            object-fit: cover;
        }
        
        main {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }
        
        #navigation-marker {
            position: absolute;
            top: -5.5rem;
        }
        
        .content-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 52%;
            font-size: 1.1rem;
            color: rgb(92, 92, 92);
        }

        #first-content-block {
            margin-top: 1.5rem;
        }
        
        @media screen and (max-width: 90rem) {
            .content-block {
                width: 70%;
            }
        }
        
        @media screen and (max-width: 65rem) {
            .content-block {
                width: 80%;
            }
        }
        
        @media screen and (max-width: 50rem) {
            .content-block {
                width: 60%;
            }
        }

        @media screen and (max-width: 30rem) {
            .content-block {
                width:77%;
            }
        }
        
        .content-headline {
            margin-top: 4rem;
            font-size: 3.2rem;
            font-weight: 600;
            color: black;
            text-align: center;
            line-height: 3.8rem;
            position: relative;
            /* color: rgba(31, 123, 255, .8); */
        }

        .content-headline::after {
            position: absolute;
            content: "";
            display: block;
            bottom: .1rem;
            width: 100%;
            height: 1.4rem;
            background: rgb(211, 8, 18, .4);
            z-index: -1;
        }

        @media screen and (max-width: 30rem) {
            .content-headline::after {
                bottom: .4rem;
                height: 1.1rem;
            }
        }
        
                
        .content-headline > div {
            position: absolute;
            top: -8rem;
        }

        @media screen and (max-width: 35rem) {
            .content-headline {
                margin-top: 2rem;
                font-size: 2.5rem;
            }
        }
        
        .content-sub-headline {
            margin-top: 1rem;
            margin-bottom: 4rem;
            font-size: 1.8rem;
            font-weight: 400;
            text-align: center;
            opacity: .7;
        }

        @media screen and (max-width: 50rem) {
            .content-sub-headline {
                margin-top: .5rem;
                margin-bottom: 2.9rem;
            }
        }

        @media screen and (max-width: 35rem) {
            .content-sub-headline {
                font-size: 1.3rem;
            }
        }
        
        .content-sub-headline-between {
            margin-top: 3rem;
        }
        
        .content-text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.7rem;
        }
        
        
        @media screen and (max-width: 50rem) {
            .content-text {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
                row-gap: 1rem;
            }
        } 
        
        #testimonial-container {
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        #testimonial-container > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 50%;
        }

        @media screen and (max-width: 50rem) {

            #testimonial-container > div {
                width: 100%;
            }

            #testimonial-container {
                flex-direction: column;
            }

        } 

        .testimonial {
            margin-bottom: 2.8rem;
            text-align: center;
            line-height: 1.9rem;
            width: 80%;
        }

        @media screen and (max-width: 50rem) {
            #testimonial-container {
                width: 100%;
            }

            .testimonial {
                width: 75%;
            }
        } 

        .testimonial > div:nth-child(2) {
            margin-top: 1.4rem;
            font-weight: 600;
            font-size: 1.3rem;
            color: rgb(0,0,0, .3);
        }

        #testimonial-container > div:nth-child(2) > div:last-child {
            margin-bottom: 0;
        }

        .audio-box {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }

        .audio-text {
            margin-top: 1.5rem;
            margin-bottom: 2.5rem;
            font-weight: 200;
            font-size: 1.4rem;
            opacity: .8rem;
            text-align: center;
        }

        /*#blocks-left {
            bottom: -6rem;
            left: 0;
            position: absolute;
            width: 22rem;
            z-index: 3;
        }*/

        #blocks-left {
            top: 4rem;
            left: 1rem;
            position: absolute;
            width: 29rem;
            opacity: .9;
        }
        
        #blocks-right {
            top: -.5rem;
            right: 2rem;
            position: absolute;
            width: 26rem;
            opacity: 1;
            z-index: 14;
        }

        @media screen and (max-width: 90rem) {
            #blocks-right {
                display: none;
            }
        
            #blocks-left {
                display: none;
            }
        }
        
        
        /* KONTAKT SEITE */

        #contact-form {
            width: 25rem;
            max-width: 100%;
            z-index: 8;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form {
                width: 100%;
            }
        }
        
        #contact-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.1rem;
        }
        
        .contact-link {
            margin-top: 1rem;
        }
        
        input[type="text"], input[type="email"], input[type="submit"], select, textarea, button {
            box-sizing: border-box;
            margin: 0.5rem;
            padding: 0.6rem;
            min-width: 100%;
            max-width: 100%;
            border: 0.18rem solid rgb(152, 34, 72);
            background-color: #ffffff;
            outline: none;
        }
        
        select {
            cursor: pointer;
        }
        
        select option {
            outline: none;
        }
        
        textarea {
            min-height: 9rem;
            max-height: 16rem;
        }
        
        #contact-form-checkboxes {
            user-select: none;
            margin: 0.5rem;
            text-align: left;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form-checkboxes {
                text-align: center;
            }
        }
        
        input[type="checkbox"] {
            margin-right: 0.5rem;
        }
        
        #contact-form-message {
            font: 400 13.3333px Arial;
        }
        
        button {
            margin-top: 1rem;
            transition: background-color 0.3s, color 0.3s;
            cursor: pointer;
        }
        
        button:hover {
            background-color: rgb(152, 34, 72);
            color: #ffffff;

    `]
}