import {LitElement, html, css, customElement, property, query} from "lit-element"
import { GlobalCSS } from "./css"
import "@justinribeiro/code-block"
import { Router } from "@vaadin/router";

// Import components here
import "./components/navigation"
import "./components/footer"

// Import pages here
import "./pages/home"
import "./pages/gunter"
import "./pages/imprint"
import "./pages/notfound"

@customElement("app-root")
export class App extends LitElement {
    @property({type: String}) private route: string

    @query("app-navigation")
    navigation;

    router: Router;

    @query("#router-outlet")
    routerOutlet;

    currentElem;

    constructor() {
        super()
    
        /*this.route = "" // Default value
        router([{
            name: "home",
            pattern: "/",
        }, {
            name: "imprint",
            pattern: "imprint",
            data: { title: "Impressun" },
        }, {
            name: "gunter",
            pattern: "gunter",
            data: { title: "Günter Barz" },
        }, {
            name: "privacy",
            pattern: "privacy",
            data: { title: "Datenschutzerlärung" },
        }, {
            name: "not-found",
            pattern: "*",
            data: { title: "Error 404!" },
        }], (route, params, query, data) => {
            // Scroll back to top
            window.scrollTo(0, 0)

            // Change route
            this.route = route

            // Change browser tab title
            let title = "Gebatzens"
            if (data && data.title && data.title !== "") {
                title += " | " + data.title
            }
            document.title = title
        })*/
    }

    createPageElement(name: string, title: string) {
        const elem = document.createElement(name);
        elem.addEventListener("headerarea", ev => {
            this.navigation.handleHeaderAreaEvent(ev);
        });

        this.currentElem = elem;
        window.scrollTo({
            top: 0
        });

        let docTitle = "Gebatzens";
        if (title) {
            docTitle += " | " + title
        }
        document.title = docTitle;

        return elem;
    }

    firstUpdated() {
        this.router = new Router(this.routerOutlet);

        this.router.setRoutes([{
            path: "/",
            animate: true,
            action: () => this.createPageElement("app-home", "Home"),
        }, {
            path: "/gunter",
            animate: true,
            action: () => this.createPageElement("app-gunter", "Günter Barz"),
        }]);
    }

    scrollToElement(ev) {
        if (this.currentElem) {
            this.currentElem.scrollToElement(ev.detail.id);
        }
    }

    render() {
        return html`
            <app-navigation @scrollto="${ev => this.scrollToElement(ev)}" route='${this.route}'></app-navigation>
            <div id="router-outlet"></div>
            
            <app-footer></app-footer>
        `
    }

    static styles = [GlobalCSS, css`
        :host {
            background-color: #eee;
        }

        img {
            width: 20rem;
        }

        #router-outlet {
            position: relative;
        }

        #router-outlet > * {
            width: 100% !important;
            height: 100% !important;
        }

        #router-outlet > .leaving {
            animation: 0.2s fadeOut ease-in-out;
            position: initial !important;
        }

        #router-outlet > .entering {
            animation: 0.2s fadeIn ease-in-out;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
        }

        @keyframes fadeOut {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

    `]
}